import React from "react";
import GamePlayer from "./GamePlayer";

const PlayPage = () => {
    return (
        <div>
            <GamePlayer />
        </div>
    );
}

export default PlayPage;