import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import HomePage from "./HomePage";
import PlayPage from "./PlayPage";


const Routes = {
    Home: "/",
    Play: "/play",
    Settings: "/settings"
}
const router = createBrowserRouter([
    {
        path: Routes.Home,
        element: <HomePage />
    },
    {
        path: Routes.Play,
        element: <PlayPage />
    }
]);

export const Router = () => {
    return (
        <RouterProvider router={router} />
    );
}
