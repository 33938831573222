import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Font } from 'three/examples/jsm/loaders/FontLoader';

export class GameScene3D {
    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private renderer: THREE.WebGLRenderer;
    private world: CANNON.World;
    private bodies: CANNON.Body[] = [];
    private cubes: THREE.Mesh[] = [];
    private textureLoader: THREE.TextureLoader;
    private backgroundColors: THREE.Color[];
    private colorIndex: number;
    private colorT: number;
    private raycaster: THREE.Raycaster;
    private mouse: THREE.Vector2;
    private explosions: THREE.Object3D[] = [];
    private score: number = 0;
    private scoreMultiplier: number = 1;
    private lastHitTime: number = 0;
    private scoreText: THREE.Mesh | null = null;
    private scoreScene: THREE.Scene;
    private scoreCamera: THREE.OrthographicCamera;
    private font: Font | null = null;

    constructor(container: HTMLElement) {
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.renderer = new THREE.WebGLRenderer();

        this.renderer.setSize(window.innerWidth, window.innerHeight);
        container.appendChild(this.renderer.domElement);

        this.camera.position.z = 5;

        this.world = new CANNON.World({
            gravity: new CANNON.Vec3(0, -9.82, 0) // m/s²
        });

        this.textureLoader = new THREE.TextureLoader();

        // Initialize background colors
        this.backgroundColors = [
            new THREE.Color(0xff00ff), // Magenta
            new THREE.Color(0x00ffff), // Cyan
            new THREE.Color(0xffff00), // Yellow
            new THREE.Color(0xff00ff)  // Back to Magenta for smooth loop
        ];
        this.colorIndex = 0;
        this.colorT = 0;

        this.createWall(10, 5);

        this.animate();

        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();

        // Add event listener for mouse clicks
        container.addEventListener('click', this.onMouseClick.bind(this), false);

        // Initialize score-related elements
        this.scoreScene = new THREE.Scene();
        this.scoreCamera = new THREE.OrthographicCamera(
            0, window.innerWidth,
            window.innerHeight, 0,
            0.1, 1000
        );
        this.scoreCamera.position.z = 500;  // Set a position for the camera

        // Load font
        const loader = new FontLoader();
        loader.load('/assets/fonts/helvetiker_regular.typeface.json', (font) => {
            this.font = font;
            this.updateScoreDisplay();
        });
    }

    createWall(width: number, height: number) {
        const cubeSize = 1; // Size of the cube
        const cubeShape = new CANNON.Box(new CANNON.Vec3(cubeSize / 2, cubeSize / 2, cubeSize / 2));
        const cubeGeometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);

        // Load the texture
        const texture = this.textureLoader.load('/assets/textures/giacomo-face-nobkg.png');

        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                // Create materials for each face
                const materials = [
                    this.getRandomNonBackgroundColor(),
                    this.getRandomNonBackgroundColor(),
                    this.getRandomNonBackgroundColor(),
                    this.getRandomNonBackgroundColor(),
                    new THREE.MeshBasicMaterial({ map: texture }), // front (with texture)
                    this.getRandomNonBackgroundColor(),
                ];

                const cube = new THREE.Mesh(cubeGeometry, materials);

                const xPos = x - width / 2 + 0.5;
                const yPos = y - height / 2 + 0.5;
                cube.position.set(xPos * cubeSize, yPos * cubeSize, 0);
                cube.rotation.y = Math.PI; // Rotate 180 degrees around Y axis

                this.scene.add(cube);
                this.cubes.push(cube);

                // Create physics body
                const body = new CANNON.Body({
                    mass: 5, // kg
                    shape: cubeShape,
                    position: new CANNON.Vec3(xPos * cubeSize, yPos * cubeSize, 0)
                });

                this.world.addBody(body);
                this.bodies.push(body);
            }
        }

        // Add a ground plane
        const groundShape = new CANNON.Plane();
        const groundBody = new CANNON.Body({ mass: 0 }); // mass 0 makes it static
        groundBody.addShape(groundShape);
        groundBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
        groundBody.position.set(0, -height * cubeSize / 2, 0);
        this.world.addBody(groundBody);
    }

    getRandomNonBackgroundColor(): THREE.MeshBasicMaterial {
        let color: THREE.Color;
        do {
            color = new THREE.Color(Math.random(), Math.random(), Math.random());
        } while (this.isColorCloseToBackground(color));

        return new THREE.MeshBasicMaterial({ color: color });
    }

    isColorCloseToBackground(color: THREE.Color): boolean {
        return this.backgroundColors.some(bgColor =>
            Math.abs(color.r - bgColor.r) < 0.1 &&
            Math.abs(color.g - bgColor.g) < 0.1 &&
            Math.abs(color.b - bgColor.b) < 0.1
        );
    }

    onMouseClick(event: MouseEvent) {
        // Calculate mouse position in normalized device coordinates
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        // Update the picking ray with the camera and mouse position
        this.raycaster.setFromCamera(this.mouse, this.camera);

        // Calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObjects(this.cubes);

        if (intersects.length > 0) {
            const clickedCube = intersects[0].object as THREE.Mesh;
            this.explodeCube(clickedCube);
        }
    }

    explodeCube(cube: THREE.Mesh) {
        const index = this.cubes.indexOf(cube);
        if (index !== -1) {
            // Remove the cube and its corresponding body from the arrays
            this.cubes.splice(index, 1);
            const body = this.bodies.splice(index, 1)[0];

            // Remove the cube from the scene and the body from the world
            this.scene.remove(cube);
            this.world.removeBody(body);

            // Create explosion effect
            this.createExplosionEffect(cube.position);

            this.updateScore(100);
        }
    }

    createExplosionEffect(position: THREE.Vector3) {
        const explosion = new THREE.Object3D();
        this.scene.add(explosion);
        this.explosions.push(explosion);

        // Particle burst
        const particleCount = 100;
        const particleGeometry = new THREE.BufferGeometry();
        const particlePositions = new Float32Array(particleCount * 3);
        const particleColors = new Float32Array(particleCount * 3);
        const particleSizes = new Float32Array(particleCount);

        for (let i = 0; i < particleCount; i++) {
            const i3 = i * 3;
            particlePositions[i3] = position.x;
            particlePositions[i3 + 1] = position.y;
            particlePositions[i3 + 2] = position.z;

            const color = new THREE.Color();
            color.setHSL(Math.random() * 0.2 + 0.5, 1, 0.5); // Orange to red hues
            particleColors[i3] = color.r;
            particleColors[i3 + 1] = color.g;
            particleColors[i3 + 2] = color.b;

            particleSizes[i] = Math.random() * 0.2 + 0.1;
        }

        particleGeometry.setAttribute('position', new THREE.BufferAttribute(particlePositions, 3));
        particleGeometry.setAttribute('color', new THREE.BufferAttribute(particleColors, 3));
        particleGeometry.setAttribute('size', new THREE.BufferAttribute(particleSizes, 1));

        const particleMaterial = new THREE.PointsMaterial({
            size: 0.1,
            vertexColors: true,
            blending: THREE.AdditiveBlending,
            depthTest: false,
            transparent: true,
        });

        const particles = new THREE.Points(particleGeometry, particleMaterial);
        explosion.add(particles);

        // Expanding rings
        const ringCount = 3;
        const rings: THREE.Mesh[] = [];
        for (let i = 0; i < ringCount; i++) {
            const ringGeometry = new THREE.RingGeometry(0, 0.1, 32);
            const ringMaterial = new THREE.MeshBasicMaterial({
                color: new THREE.Color().setHSL(Math.random() * 0.2 + 0.5, 1, 0.5),
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 1,
            });
            const ring = new THREE.Mesh(ringGeometry, ringMaterial);
            ring.position.copy(position);
            ring.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI);
            explosion.add(ring);
            rings.push(ring);
        }

        // Shockwave
        const shockwaveGeometry = new THREE.SphereGeometry(0.1, 32, 32);
        const shockwaveMaterial = new THREE.MeshBasicMaterial({
            color: 0xffff00,
            side: THREE.BackSide,
            transparent: true,
            opacity: 0.5,
        });
        const shockwave = new THREE.Mesh(shockwaveGeometry, shockwaveMaterial);
        shockwave.position.copy(position);
        explosion.add(shockwave);

        // Animate the explosion
        const startTime = Date.now();
        const animateExplosion = () => {
            const elapsedTime = (Date.now() - startTime) / 1000; // time in seconds
            const positions = particles.geometry.attributes.position.array as Float32Array;
            const sizes = particles.geometry.attributes.size.array as Float32Array;

            for (let i = 0; i < particleCount; i++) {
                const i3 = i * 3;
                positions[i3] += (Math.random() - 0.5) * 0.3;
                positions[i3 + 1] += (Math.random() - 0.5) * 0.3;
                positions[i3 + 2] += (Math.random() - 0.5) * 0.3;
                sizes[i] *= 0.98; // Shrink particles
            }

            particles.geometry.attributes.position.needsUpdate = true;
            particles.geometry.attributes.size.needsUpdate = true;

            // Animate rings
            rings.forEach((ring, index) => {
                ring.scale.setScalar(1 + elapsedTime * (index + 1) * 2);
                (ring.material as THREE.MeshBasicMaterial).opacity = Math.max(0, 1 - elapsedTime);
            });

            // Animate shockwave
            shockwave.scale.setScalar(1 + elapsedTime * 5);
            shockwaveMaterial.opacity = Math.max(0, 0.5 - elapsedTime * 0.5);

            if (elapsedTime < 2) {
                requestAnimationFrame(animateExplosion);
            } else {
                this.scene.remove(explosion);
                this.explosions = this.explosions.filter(e => e !== explosion);
            }
        };

        animateExplosion();
    }

    animate = () => {
        requestAnimationFrame(this.animate);

        // Step the physics world
        this.world.step(1 / 60);

        // Update cube positions
        for (let i = 0; i < this.cubes.length; i++) {
            if (this.cubes[i] && this.bodies[i]) {
                this.cubes[i].position.copy(this.bodies[i].position as any);
                this.cubes[i].quaternion.copy(this.bodies[i].quaternion as any);
            }
        }

        // Update background color
        this.updateBackgroundColor();

        // Update explosions
        this.explosions = this.explosions.filter(explosion => {
            if (explosion && explosion.parent) {
                explosion.children.forEach(child => {
                    if (child instanceof THREE.Points) {
                        child.rotation.y += 0.01;
                    }
                });
                return true;
            }
            return false;
        });

        if (this.renderer && this.scene && this.camera) {
            this.renderer.render(this.scene, this.camera);

            if (this.scoreScene && this.scoreCamera) {
                this.renderer.autoClear = false;
                //this.renderer.render(this.scoreScene, this.scoreCamera);
                this.renderer.autoClear = true;
            }
        }
    };

    updateBackgroundColor() {
        this.colorT += 0.005; // Adjust this value to change color transition speed
        if (this.colorT > 1) {
            this.colorT = 0;
            this.colorIndex = (this.colorIndex + 1) % (this.backgroundColors.length - 1);
        }

        const currentColor = this.backgroundColors[this.colorIndex];
        const nextColor = this.backgroundColors[this.colorIndex + 1];
        const interpolatedColor = new THREE.Color();
        interpolatedColor.lerpColors(currentColor, nextColor, this.colorT);

        this.scene.background = interpolatedColor;
    }

    updateScoreDisplay() {
        if (!this.font || !this.scoreScene) return;

        if (this.scoreText) {
            this.scoreScene.remove(this.scoreText);
        }

        const geometry = new TextGeometry(`Score: ${this.score}`, {
            font: this.font,
            size: 50,
            height: 5,
            curveSegments: 12,
            bevelEnabled: false,
        });

        const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
        this.scoreText = new THREE.Mesh(geometry, material);
        this.scoreText.position.set(10, window.innerHeight - 60, 0);
        this.scoreScene.add(this.scoreText);
    }

    updateScore(points: number) {
        const currentTime = Date.now();
        if (currentTime - this.lastHitTime < 1000) { // 1 second window for multiplier
            this.scoreMultiplier++;
        } else {
            this.scoreMultiplier = 1;
        }
        this.lastHitTime = currentTime;

        this.score += points * this.scoreMultiplier;
        this.updateScoreDisplay();
    }

    dispose() {
        this.renderer.dispose();
    }
}
