import React from "react";
import { Outlet, Link } from 'react-router-dom';

import logo from '../logo.svg';

const HomePage = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={logo} className="App-logo" alt="logo" />
            <div style={{ marginTop: '20px' }}>
                <Link to="/play" style={{ fontSize: '64px', color: 'white', textDecoration: 'none' }}>Play</Link>
            </div>
        </div>
    );
}

export default HomePage;