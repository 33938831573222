import React, { useEffect, useRef } from 'react';
import { GameScene3D } from '../game/GameScene3D';

const GamePlayer = () => {
    const mountRef = useRef<HTMLDivElement>(null);
    const sceneRef = useRef<GameScene3D | null>(null);

    useEffect(() => {
        if (!mountRef.current) return;

        sceneRef.current = new GameScene3D(mountRef.current);
        sceneRef.current.animate();

        return () => {
            if (sceneRef.current) {
                sceneRef.current.dispose();
                sceneRef.current = null;
            }
        };
    }, []);

    return <div ref={mountRef} />;
};

export default GamePlayer;
